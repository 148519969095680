import Vue from 'vue'
import VueRouter from 'vue-router'
import NotFound from '../views/page-not-found.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      title: 'home'
    },
    component: () =>
      import("../views/page-home.vue"),
  },
  {
    path: "/projects",
    name: "projects",
    meta: {
      title: 'projects'
    },
    component: () =>
      import("../views/page-projects.vue"),
  },
  {
    path: "/publications",
    name: "publications",
    meta: {
      title: 'publications'
    },
    component: () =>
      import("../views/page-publications.vue"),
  },

  {
    path: "/publication/001",
    name: "publication/001 origins",
    meta: {
      title: '001 origins'
    },
    component: () =>
      import("../publications/001-pub.vue"),
  },

  {
    path: "/publication/002",
    name: "publication/002 8 frames on Ilford HP5+ with the Zorki 4K",
    meta: {
      title: '002 8 frames on Ilford HP5+ with the Zorki 4K'
    },
    component: () =>
      import("../publications/002-pub.vue"),
  },


  {
    path: "/publication/003",
    name: "publication/003 Zorki 4K Camera Review",
    meta: {
      title: '002 Zorki 4K Camera Review'
    },
    component: () =>
      import("../publications/003-pub.vue"),
  },







  {
    path: "/about",
    name: "about",
    meta: {
      title: 'about'
    },
    component: () =>
      import("../views/page-about.vue"),
  },
  { path: '/home', redirect: { name: 'home' } },



  {
    path: '*',
    name: NotFound,
    meta: {
      title: '404'
    },
    component: () =>
      import("../views/page-not-found.vue")
  }
];

const router = new VueRouter({
  mode: 'hash',
  routes,
  scrollBehavior() {
    // always scroll to top
    return {
      top: 0,
      behavior: "smooth"
    }
  }
})


router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + "  |  heavygrain.uk";
  }
});


export default router;