<template>
  <div id="app" class="tokyo_tm_all_wrap dark">
    <!-- Start leftpart -->
    <div class="leftpart">
      <div class="leftpart_inner">
        <div class="logo">
          <router-link to="/">
            <div><img src="./assets/img/logo/heavygrainlogo.png" alt="" class="hg-logo"/></div>
          </router-link>
        </div>
        <!-- End .logo -->
        <div class="menu">
          <router-link to="/">
            <img class="svg" src="./assets/img/svg/home-run.svg" alt="homerun" />
            <span class="menu_content">Home</span>
          </router-link>
          <!-- End Home -->
          
          <!-- <router-link to="/portfolio">
            <img class="svg" src="./assets/img/svg/briefcase.svg" alt="briefcase"/>
            <span class="menu_content">Portfolio</span></a
          > -->
          <!-- End Portfolio -->

          <router-link to="/projects">
            <img class="svg" src="./assets/img/svg/briefcase.svg" alt="briefcase" />
            <span class="menu_content">Projects</span>
          </router-link>
          <!-- End Projects -->

          <router-link to="/publications">
            <img class="svg" src="./assets/img/svg/paper.svg" alt="paper" />
            <span class="menu_content">Publications</span>
          </router-link>
          <!-- End Publications -->

          <router-link to="/about">
            <img class="svg" src="./assets/img/svg/avatar.svg" alt="avatar" />
            <span class="menu_content">About</span>
          </router-link>
          <!-- End About -->

        </div>
        <div class="copyright">
          <p>
            &copy; {{ new Date().getFullYear() }} heavygrain.uk<br />Created by Alexander Harris
          </p>
        </div>
        <!-- End copyright -->
      </div>
    </div>
    <!-- End leftpart -->
    <div class="rightpart">
      <div class="rightpart_in">
        <div class="tokyo_tm_section" id="scrollContent">
          <div class="container">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    mounted() {
      document.body.classList.add("dark");
    },
    destroyed() {
      document.body.classList.remove("dark");
    },
  };
</script>
